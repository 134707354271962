.block {
    display: block;
}

.mb-3 {
    margin-bottom: 1rem;
}

form input[type='submit'] {
    cursor: pointer;
}

.inputcanvas {
    display: inline-flex;
}

.inputcanvas>input {
    width: 50%;
}

select {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}