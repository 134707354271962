.modal_img {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 49%);
    opacity: 0;
    z-index: -1;
    transition: .5s all;
}

.modal_img.show {
    opacity: 1;
    z-index: 1000;
}

.dimmisingModal {
    position: relative;
    top: 14.5rem;
    left: 86.5%;
    z-index: 10;
    cursor: pointer;
    color: #fff;
    font-weight: bolder;
    width: 50px;
}

.contentImagen {
    width: 70%;
    margin: 5rem auto 0;
}

.preview{
    margin-bottom: 1rem;
}

.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Ajusta el color y la opacidad según sea necesario */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Asegura que esté por encima de otros elementos */
  }

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .fuentes{
    padding: 25px;
  }

    
  .fuentes ol{
    line-height: 1;
    font-family: 'Times New Roman', Times, serif;
  }