@font-face {
    font-family: 'Metropolis Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular'), url('../public/assets/fonts/Metropolis/Metropolis-Regular.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-Regular.otf');
}

.Metropolis-Regular {
    font-family: 'Metropolis Regular';
}

@font-face {
    font-family: 'Metropolis Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular Italic'), url('../public/assets/fonts/Metropolis/Metropolis-RegularItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-RegularItalic.otf');
}

.Metropolis-Regular-Italic {
    font-family: 'Metropolis Regular Italic';
}

@font-face {
    font-family: 'Metropolis Extra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Light'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraLight.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraLight.otf');
}

.Metropolis-Extra-Light {
    font-family: 'Metropolis Extra Light';
}

@font-face {
    font-family: 'Metropolis Light';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Light'), url('../public/assets/fonts/Metropolis/Metropolis-Light.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-Light.otf');
}

.Metropolis-Light {
    font-family: 'Metropolis Light';
}

@font-face {
    font-family: 'Metropolis Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Thin'), url('../public/assets/fonts/Metropolis/Metropolis-Thin.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-Thin.otf');
}

.Metropolis-Thin {
    font-family: 'Metropolis Thin';
}

@font-face {
    font-family: 'Metropolis Extra Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Light Italic'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraLightItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraLightItalic.otf');
}

.Metropolis-Extra-Light-Italic {
    font-family: 'Metropolis Extra Light Italic';
}

@font-face {
    font-family: 'Metropolis Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Light Italic'), url('../public/assets/fonts/Metropolis/Metropolis-LightItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-LightItalic.otf');
}

.Metropolis-Light-Italic {
    font-family: 'Metropolis Light Italic';
}

@font-face {
    font-family: 'Metropolis Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Thin Italic'), url('../public/assets/fonts/Metropolis/Metropolis-ThinItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-ThinItalic.otf');
}

.Metropolis-Thin-Italic {
    font-family: 'Metropolis Thin Italic';
}

@font-face {
    font-family: 'Metropolis Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Medium'), url('../public/assets/fonts/Metropolis/Metropolis-Medium.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-Medium.otf');
}

.Metropolis-Medium {
    font-family: 'Metropolis Medium';
}

@font-face {
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold'), url('../public/assets/fonts/Metropolis/Metropolis-SemiBold.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-SemiBold.otf');
}

.Metropolis-Semi-Bold {
    font-family: 'Metropolis Semi Bold';
}

@font-face {
    font-family: 'Metropolis Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold'), url('../public/assets/fonts/Metropolis/Metropolis-Bold.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-Bold.otf');
}

.Metropolis-Bold {
    font-family: 'Metropolis Bold';
}

@font-face {
    font-family: 'Metropolis Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold Italic'), url('../public/assets/fonts/Metropolis/Metropolis-BoldItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-BoldItalic.otf');
}

.Metropolis-Bold-Italic {
    font-family: 'Metropolis Bold Italic';
}

@font-face {
    font-family: 'Metropolis Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Medium Italic'), url('../public/assets/fonts/Metropolis/Metropolis-MediumItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-MediumItalic.otf');
}

.Metropolis-Medium-Italic {
    font-family: 'Metropolis Medium Italic';
}

@font-face {
    font-family: 'Metropolis Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold Italic'), url('../public/assets/fonts/Metropolis/Metropolis-SemiBoldItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-SemiBoldItalic.otf');
}

.Metropolis-Semi-Bold-Italic {
    font-family: 'Metropolis Semi Bold Italic';
}

@font-face {
    font-family: 'Metropolis Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Bold'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraBold.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraBold.otf');
}

.Metropolis-Extra-Bold {
    font-family: 'Metropolis Extra Bold';
}

@font-face {
    font-family: 'Metropolis Extra Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Bold Italic'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraBoldItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-ExtraBoldItalic.otf');
}

.Metropolis-Extra-Bold-Italic {
    font-family: 'Metropolis Extra Bold Italic';
}

@font-face {
    font-family: 'Metropolis Black';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Black'), url('../public/assets/fonts/Metropolis/Metropolis-Black.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-Black.otf');
}

.Metropolis-Black {
    font-family: 'Metropolis Black';
}

@font-face {
    font-family: 'Metropolis Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Black Italic'), url('../public/assets/fonts/Metropolis/Metropolis-BlackItalic.woff') format('woff'), url('../public/assets/fonts/Metropolis/Metropolis-BlackItalic.otf');
}

.Metropolis-Black-Italic {
    font-family: 'Metropolis Black Italic';
}

@font-face {
    font-family: 'Arial Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Arial Bold'), url('../public/assets/fonts/Arial/Arial\ Bold.ttf') format('ttf');
}

.Arial-Bold {
    font-family: 'Arial Bold';
}

@font-face {
    font-family: 'Lato Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Bold'), url('../public/assets/fonts/Lato/Lato-Bold.ttf') format('ttf');
}

.Lato-Bold {
    font-family: 'Lato Bold';
}

@font-face {
    font-family: 'Lato Light';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Light'), url('../public/assets/fonts/Lato/Lato-Light.ttf') format('ttf');
}

.Lato-Light {
    font-family: 'Lato Light';
}

@font-face {
    font-family: 'MyriadPro-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('MyriadPro-Regular'), url('../public/assets/fonts/MyriadPro/MyriadPro-Regular.otf') format('otf');
}

.MyriadPro-Regular {
    font-family: 'MyriadPro-Regular';
}

@font-face {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('OpenSans-Bold'), url('../public/assets/fonts/OpenSans/OpenSans-Bold.ttf') format('ttf');
}

.OpenSans-Bold {
    font-family: 'OpenSans-Bold';
}

@font-face {
    font-family: 'OpenSans-Light';
    font-style: normal;
    font-weight: normal;
    src: local('OpenSans-Light'), url('../public/assets/fonts/OpenSans/OpenSans-Light.ttf') format('ttf');
}

.OpenSans-Light {
    font-family: 'OpenSans-Light';
}

@media (max-width:780px) {
    .d-sm-none {
        display: none;
    }
}

.color-titulos {
    color: #243f59
}

@tailwind base;
@tailwind components;
@tailwind utilities;