.permisosCheck {
    display: flex;
    flex-wrap: wrap;
}

.permisosCheck>div {
    margin: 5px;
    border: 1px solid lightgray;
    box-shadow: 2px 2px 8px 4px #d3d3d3d1;
    border-radius:8px;
}


.permisosCheck ul {
    border: 1px solid black;
    height: 180px;
    width: 120px;
    padding: 0 5px;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(90, 0, 0, 0.2);
    background-color: #ececec;
}


.permisosCheck ul li {}


.permisosCheck ul li input {}

.permisosCheck ul li label {
    padding-left: 6px;
}